import React, {useEffect} from "react";
import Lang from "./Lang";
import { useTranslation } from "react-i18next";


const Header = () => {

    const {t, i18n} = useTranslation()
    useEffect(()=>{
        const language = localStorage.getItem("language");
        if(language?.length > 0 ){
            i18n.changeLanguage(language).then(r => {})
        }
    },[])

    const homeClass = (window.location.pathname.match(/^\/home/) || window.location.pathname === "/")
      ? "nav-link scrollto active" : "nav-link scrollto";

    const contactClass = window.location.pathname.match(/^\/contact/) ? "nav-link scrollto active"
      : "nav-link scrollto";

    return (
      <header id='header' className='fixed-top'>
        <div className='container d-flex align-items-center'>

          <h1 className='logo me-auto'><a href='/home'><img src='/assets/img/pategougroup.png' alt='logo'/></a></h1>
          <nav id='navbar' className='navbar'>
            <ul>
              <li><a className={homeClass} href='/home'>{t('navigation-menu.home')}</a></li>
              <li><a className={contactClass} href='/contact'>Contact</a></li>
              <li className={'nav-link scrollto'}>
                <Lang  />
              </li>
            </ul>
            <i className='bi bi-list mobile-nav-toggle'/>
          </nav>
        </div>
      </header>
    )
}

export default Header
