import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {myConfig} from "../config";
const Footer = () =>{
  const {t, i18n} = useTranslation()
  const [email, setEmail] = useState("");
  const [errorMsg , setErrorMsg] = useState("")
  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])

  const handleChange = (input, e) => {
    if (input === "email") {
      setEmail(e.target.value);
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const messages = {
      email: email,
      source : "Pategou",
    }
    axios.post(myConfig.apiMailNewsLetter, messages)
        .then(result => {
          console.log(result.data)
          setErrorMsg(t('contact-newsletter.succesMsg'))

        })
        .catch(exception => {
          setErrorMsg(t('contact-newsletter.errorMsg'))
        })
    setEmail('');
  };


  return (
      <footer id='footer'>
        <div className='footer-top'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-md-6 footer-contact'>
                <h3><a href='/home'>Pategou Business Center</a></h3>
                <p>
                  <b>{t('footer.bloc-1.cameroon-title')} </b> <br/>{t('footer.bloc-1.cameroon-location')}<br/>
                  {t('footer.bloc-1.cameroon-pobox')}<br/>
                  {t('footer.bloc-1.phone')} (+237) 671 61 90 90 <br/> <span style={{marginLeft:'8.3em'}}>233 42 06 84</span>
                  <br/>{t('footer.bloc-1.whatsapp-phone')} : (+237) 657 25 37 37
                  <br/>
                  <span>{t('footer.bloc-1.mail')}</span> <a target={'_blank'} href={'mailto:contact@pategou.com'}>contact@pategou.com</a> <br/>
                  <span>{t('footer.bloc-1.web-site')}</span> <span><a href={'https://business-center.pategou.com'} target={'_blank'}>business-center.pategou.com</a></span>
                </p>
              </div>

              <div className='col-lg-4 col-md-6 footer-contact'>
                <h3><a href='/home'>Pategou Consulting</a></h3>
                <p>
                  <b>France</b> <br/>{t('footer.bloc-1.france-location')}<br/>
                  {t('footer.bloc-1.phone')} +33 648 37 46 18
                </p>
                <p>
                  <b>{t('footer.bloc-1.cameroon-title')} </b> <br/>{t('footer.bloc-1.cameroon-location')}<br/>
                  {t('footer.bloc-1.cameroon-pobox')}<br/>
                  {t('footer.bloc-1.phone')} (+237) 671 61 90 90 <br/> <span style={{marginLeft:'8.3em'}}>233 42 06 84</span>
                  <br/>{t('footer.bloc-1.whatsapp-phone')} : (+237) 657 25 37 37
                  <br/>
                  <span>{t('footer.bloc-1.mail')}</span> <a target={'_blank'} href={'mailto:contact@pategou.com'}>contact@pategou-consulting.com</a> <br/>
                  <span>{t('footer.bloc-1.web-site')}</span> <span><a target={'_blank'} href={'https://consulting.pategou.com'}>consulting.pategou.com</a></span>
                </p>
              </div>

              <div className='col-lg-4 col-md-6 footer-links'>
                <h4>{t('footer.bloc-4.title')}</h4>
                <p>{t('footer.bloc-4.description')}</p>
                <div className='social-links mt-3'>
                  <a href='https://twitter.com/PategouCenter?t=BLnfHesT3v_X30drYCq6ZA&s=08' rel='noopener noreferrer' target='_blank' className='twitter'><i
                    className='bx bxl-twitter'></i></a>
                  <a href='https://www.facebook.com/Pategou-Business-Center-101801771581377' rel='noopener noreferrer' target='_blank' className='facebook'><i
                    className='bx bxl-facebook'></i></a>
                  <a href='https://www.linkedin.com/company/79530682/admin/' rel='noopener noreferrer' target='_blank' className='linkedin'><i
                      className='bx bxl-linkedin'></i></a>
                  <a href='https://www.instagram.com/pategou_business_center/' rel='noopener noreferrer' target='_blank' className='instagram'><i
                    className='bx bxl-instagram'></i></a>
                  <a href='https://wa.me/237657253737' rel='noopener noreferrer' target='_blank' className='google-plus'><i
                    className='bx bxl-whatsapp'></i></a>

                </div>
                <div className='mt-3 footer-newsletter'>
                  <h4>{t('footer.bloc-4.text-newsletter')}</h4>
                  <p>{errorMsg}</p>
                  <form onSubmit={handleSubmit}>
                  <div>
                    <input
                        onChange={(e) => handleChange("email", e)}
                        type='email'
                        placeholder={t('footer.bloc-4.text-placeholder')}
                        name='email'
                        required/><input type='submit' value={t('footer.bloc-4.text-button-subscribe')}/>
                  </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='container footer-bottom clearfix'>
          <div className='copyright'>
            &copy; Copyright 2022 <strong><span>Pategou</span></strong>. {t('footer.all-rights-reserved')}
          </div>
          <div className='credits'>
            {t('footer.designed-by')} <a href='https://consulting.pategou.com/'>Pategou Consulting</a>
          </div>
        </div>
      </footer>
    )
}

export default Footer
