import './App.css';
import React from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./pages/Contact"

class App extends React.Component {
  render() {
    return (
        <div>
          <div>
            <Router>
              <Header/>
              <Switch>
                <Redirect from='/' to='home' exact/>
                <Route exact path='/home' component={Home}/>
                <Route exact path='/contact' component={Contact}/>
              </Switch>
            </Router>
          </div>
          <Footer/>
        </div>
    );
  }
}

export default App;
