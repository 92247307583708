import React, {useEffect} from "react";

import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Home = () => {
  const {t, i18n} = useTranslation()
  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])

    return (
      <main className={'section-bg'} id='main'>
        <section id='hero' className='d-flex align-items-center home ' data-aos='zoom-out'  data-aos-delay='150'>

          <div className='container' style={{backgroundColor:'rgba(8, 8, 8, .2)'}}>
            <h1>PATEGOU GROUP</h1>

            <p style={{color:'white'}}><b>{t('slogan')}</b></p>

          </div>

        </section>

        <section id='about' className='about'>
          <div className='container'>
            <div id='why-us' className=' why-us paddingLeftImg' >
              <div className='section-title'>
                <h2><strong>{t('home-about.title')}</strong></h2>
              </div>
              <div className='paragraph-section-title' data-aos='fade-up'>
                <p>
                  {t('home-about.paragraph1')}
                </p>
                <p>
                  {t('home-about.paragraph2')}
                </p>
              </div>
              <div className='row'>
                <div className={'col-lg-6 col-md-6 home-card'}>
                  <Card sx={{ maxWidth: 'auto', height:'19em'  }} data-aos='zoom-in'
                        data-aos-delay='150'>
                    <a target={'_blank'} rel="noreferrer"  href={'https://business-center.pategou.com'}>
                      <CardMedia
                          component="img"
                          height="140"
                          image="assets/img/imgBC.jpg"
                          alt="green iguana"
                      />
                    </a>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Pategou Business Center
                      </Typography>
                      <Typography variant="body2" color="text.secondary">

                        {t('description-business-center')}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small"><span><a target={'_blank'} rel="noreferrer"  href={'https://business-center.pategou.com'}>{t('go-to-web-site')}</a></span></Button>
                    </CardActions>
                  </Card>
                </div>
                <div className={'col-lg-6 col-md-6 home-card'}>
                  <Card sx={{ maxWidth: 'auto', height:'19em' }} data-aos='zoom-in'
                        data-aos-delay='150'>
                    <a target={'_blank'} rel="noreferrer"  href={'https://consulting.pategou.com'}>
                      <CardMedia
                          component="img"
                          height="140"
                          image="assets/img/imgCon.png"
                          alt="green iguana"
                      />
                    </a>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Pategou Consulting
                      </Typography>
                      <Typography variant="body2" color="text.secondary">

                        {t('description-consulting')}<br/>{' '}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small"><span><a target={'_blank'} rel="noreferrer"  href={'https://consulting.pategou.com'}>{t('go-to-web-site')}</a></span></Button>

                    </CardActions>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
}

export default Home
